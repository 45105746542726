@import './fonts/style.css';

body {
  background: #fcfdfd;
}

@media (min-width: 0px) {
  .gallery-img{
    width: 100%;
  }
}

@media (min-width: 900px) {
  .gallery-img {
      width: 49.5%;
    }
}