/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Futura Std Book';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Book'), url('FuturaStdBook.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Book Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Book Oblique'), url('FuturaStdBookOblique.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Light Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Light Condensed'), url('FuturaStdCondensedLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Light';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Light'), url('FuturaStdLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Light Condensed Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Light Condensed Oblique'), url('FuturaStdCondensedLightObl.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Light Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Light Oblique'), url('FuturaStdLightOblique.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Medium Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Medium Condensed'), url('FuturaStdCondensed.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Medium'), url('FuturaStdMedium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Medium Condensed Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Medium Condensed Oblique'), url('FuturaStdCondensedOblique.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Medium Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Medium Oblique'), url('FuturaStdMediumOblique.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Heavy'), url('FuturaStdHeavy.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Heavy Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Heavy Oblique'), url('FuturaStdHeavyOblique.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Bold'), url('FuturaStdBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Bold Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Bold Condensed'), url('FuturaStdCondensedBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Bold Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Bold Oblique'), url('FuturaStdBoldOblique.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Bold Condensed Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Bold Condensed Oblique'), url('FuturaStdCondensedBoldObl.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Extra Bold Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Extra Bold Condensed'), url('FuturaStdCondensedExtraBd.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Extra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Extra Bold'), url('FuturaStdExtraBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Extra Bold Condensed Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Extra Bold Condensed Oblique'), url('FuturaStdCondExtraBoldObl.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura Std Extra Bold Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Extra Bold Oblique'), url('FuturaStdExtraBoldOblique.woff') format('woff');
    }